.sidebar {
  height: 80%;
  border-right-width: 0px !important;
  border-right-style: none !important;
}
.sidebar {
  ::-webkit-scrollbar {
    display: none;
  }
}
.logout-btn {
  border: none;
  background: var(--blue-primary);
  color: white;
  border-radius: 10px;
  // padding: 10px;
}
.ps-submenu-expand-icon.css-viw5ch {
  display: none !important;
}

.sidebar-section-title {
  color: rgba(36, 34, 32, 0.4);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.6875rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem; /* 218.182% */
  letter-spacing: 0.025rem;
  text-transform: uppercase;
  // padding: 0px 15px;
  color: var(--blue-primary);
}

.ps-menu-button.ps-active {
  // background-color: var(--blue-primary);
  color: var(--blue-primary) !important;
}

.ps-submenu-content.ps-open .ps-menu-button.ps-active {
  // background-color: var(--blue-primary);
  color: var(--blue-primary);
}

.collapse-btn {
  font-size: 24px;
  border-radius: 50%;
  color: var(--blue-primary);
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  transition: all 0.25s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
  &:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
}

.sidebar-header {
  padding: 10px;
  height: 60px;
  overflow: hidden;
  background-color: #fff;
  border-width: 0px 0px 1px 0px;
  border-color: #ddd;
  border-style: solid;
  .logo-container {
    width: 80px;
    height: 40px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.css-dip3t8 {
  z-index: 0 !important;
}
