.main-content {
  width: calc(100% - 250px);
  /* width: 100%; */
  height: 100vh;
  background: #f8f9fa;
  transition: all 0.4s;
  padding: 0px 16px;
  position: fixed;
  right: 0;
}

.main-content.collapsed {
  width: calc(100% - 45px);
}
.page-content {
  height: 100%;
}
.container-fluid {
  height: 100%;
}
.setting-content {
  margin: 0 auto;
  height: 100vh;
  background: #f4f7fa;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #edf1fb !important;
  cursor: default;
}
.tittle_main {
  background-color: #e4ecff;
  border-radius: 4px;
  padding: 8px;
  margin: 0;
}
.tittle_mainSub {
  background-color: #e2e8f7;
  border-radius: 4px;
  padding: 8px;
  margin: 0;
}
.tittle_mainSub_sub {
  background-color: #f0f3f9;
  border-radius: 4px;
  padding: 8px;
  margin: 0;
}
.badge-branch {
  position: absolute;
  top: 6px;
  color: #fff;
}
.badge-branchSub {
  position: absolute;
  top: -5px;
  color: #fff;
  right: 6px;
}
.input-group-sm > .btn,
.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text {
  border-radius: 0.2rem;
  font-size: 0.71094rem;
  padding: 0.25rem 0.5rem;
}
.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(
    .invalid-feedback
  ) {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}
