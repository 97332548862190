.progress-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  height: 100%;
  z-index: 9999;
  background: rgba(75, 73, 73, 0.5);
}
.progress-wrapper div.progress-bar {
  background-color: transparent !important;
  position: fixed;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
}

.progress-wrapper .progress-bar .circle {
  height: 100px;
  border: solid 5px #ffffff !important;
  border-top-color: #bf202f !important;
  border-radius: 50%;
}

.progress-wrapper .progress-bar .border {
  width: 100px;
  transform: rotate(135deg);
  animation: spin 1s infinite;
  -webkit-animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
