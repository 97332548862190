.system-selection {
  background: #f0f2f5;
  width: 100vw;
  height: 100vh;
}
.system-selecetion-nav {
  height: 10%;
  display: flex;
  top: 0;
  .logout {
    color: var(--blue-primary);
    border: none;
    gap: 10px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
  }
  .logout:hover {
    color: rgb(217, 63, 33);
    transform: scale(1.1);
    transition: all 0.5s;
  }
  .logout:focus {
    border: none;
    outline: none;
  }
}

.card-nav {
  text-decoration: none;

  p {
    color: #0c0426;
  }
}
.card-nav:hover {
  text-decoration: none;
}
.system-selection-choose {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .card {
    padding-top: 20px;
    padding-bottom: 10px;
    // background: none;
    align-items: center;
    height: 400px;
    border: none;
    border-radius: 8px;
    box-shadow: 0px 0px 19px 0px #e7e7e74d;

    .card-img-top {
      height: 160px;
      width: 160px;
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .card-title {
        color: var(--blue-primary) !important;
        text-decoration: none;
        font-weight: 500;
        text-align: center;
        text-transform: uppercase;
      }
      .card-text {
        text-align: center;
        font-size: 12px;
        text-align: justify;
      }
      a {
        width: 100%;
        .login-btn {
          width: 100%;
          border: 1px #5a5a5a solid;
          padding-top: 5px;
          padding-bottom: 5px;
          background: none;
          border-radius: 0.25rem;
        }
        .login-btn:focus,
        .login-btn:hover {
          background-color: var(--blue-primary);
          color: white;
          border: 1px var(--blue-primary) solid;
          transition: all 0.5s;
        }
      }
    }
  }
  .card {
    transition: all 0.5s ease-in-out;
  }

  .card:hover {
    transform: scale(1.05);
  }
}
